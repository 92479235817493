import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const Accessibility = ({ data }) => {

	const accessibility = data.allMarkdownRemark.nodes;

	return (
		<Layout>
            <SEO title="Accessibility" description="Appvity" meta={[{ name: `keywords`, content: ["collaboration", "task management"] }]} pathname="/accessibility" />
			<div className="main-content-padding">
				<div className="app-text-template-introduction accessibility" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <h2>Accessibility</h2>
                    </Container>
                </div>
				<div className="app-general-seciton app-text-template-body accessibility-body" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
							<Col xs={12} md={12} lg={2}></Col>
							{/* <Col xs={12} md={12} lg={3}>
								<ul className="app-text-template-body-detail-left">
									{accessibility.map((res) => (
										<li 
											key={res.id} 
											onClick={() => {
												var scrollDiv = document.getElementById(res.frontmatter.keyNumber).offsetTop + 370;
												window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
											}}
										>{res.frontmatter.title}</li>
									))}
								</ul>
							</Col> */}
							<Col xs={12} md={12} lg={8}>
								<ul className="app-text-template-body-detail-right">
									{accessibility.map((res) => (
										<li key={res.id} id={res.frontmatter.keyNumber}><h5>{res.frontmatter.title}</h5>
											<div dangerouslySetInnerHTML={{ __html: res.html }} />
										</li>
									))}
								</ul>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
                    </Container>
                </div>
			</div>
		</Layout>
	)
}

export default Accessibility;

export const query = graphql`
    query AccessibilityPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "accessibilities" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
					keyNumber
                }
                id
            }
        }
    }
`;